
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
// !! 전체 서비스에 모든 영향을 미치는 컴포넌트입니다.
// !! 수정하려면, 컴포넌트 담당자와 미리 상의해주세요.

.dialog {
  position: fixed;
  z-index: 400;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px;

  &[data-dialog-drawer='true'] {
    @include mobile {
      --dialog-height: 100%;

      justify-content: flex-end;
      padding: calc(50px + max(env(safe-area-inset-top), 10px)) 0 0;
    }
  }

  @apply focus-visible:outline-none;
}

.header {
  @include tablet {
    @apply py-md px-[24px]
  }

  > * {
    flex: 1;
  }

  & + .content {
    padding-top: 0;
  }

  & + .footer {
    padding-top: 0;
  }

  .col {
    @apply flex gap-xs items-center;
  }

  .topSlot {
    @apply text-heading-lg;
  }


  .slot {
    @apply flex flex-none gap-[10px] items-start h-full;

    line-height: 1;

    &.title {
      flex: 1;
    }
  }

  .close:focus-visible {
    border-radius: 4px;
    outline: none;
    box-shadow: $focus-ring;
  }

  @apply flex w-full flex-col gap-xs pt-md px-[24px] pb-xs;
}

.content {
  @include tablet {
    @apply p-md
  }

  overflow: hidden auto;
  flex: 1;
  padding: 24px;

  &.pre_line {
    white-space: pre-line;
  }

  & ~ .footer {
    padding-top: 0;
  }

  @supports (overflow: overlay) {
    overflow: hidden overlay;
  }
}

.footer {
  @include tablet {
    padding: 24px 16px;
  }

  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  padding: 24px;

  &::after {
    content: '';

    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: calc(100% + 24px);

    background: var(--after-background)
  }

  > * {
    position: relative;
    z-index: 1;
    flex: 1;
  }

  &.vertical > * {
    flex: 0 0 100%;
  }
}
